<template>
  <div class="col-md-4">
    <div class="class-card" v-if="type == 'upcoming'">
      <div class="_image-wrap">
        <img :src="schedule.image" alt="" />
        <router-link
          :to="{ name: 'event', params: { id: schedule.id } }"
          class="btn cta-single"
          target="_blank"
        >
          <i class="fas fa-play"></i>
        </router-link>
        <div class="_date-box">
          {{ schedule.date }}<br />
          {{ schedule.month }}
        </div>
      </div>
      <div class="_body-wrap">
        <h6>{{ schedule.title }}</h6>
        <p>
          <i class="far fa-clock"></i>{{ schedule.time }}
          <span v-if="schedule.is_live == true" class="live-schedule">
            Streaming now</span
          >
        </p>
      </div>
    </div>
    <div class="class-card" v-else>
      <div class="_image-wrap">
        <img :src="schedule.image" alt="" />
        <router-link
          :to="{ name: 'nestRecordings', params: { id: schedule.id } }"
          class="btn cta-single"
        >
          <i class="fas fa-play"></i>
        </router-link>
        <div class="_date-box">
          {{ schedule.date }} <br />
          {{ schedule.month }}
        </div>
      </div>
      <div class="_body-wrap">
        <h6>{{ schedule.title }}</h6>
        <p><i class="far fa-clock"></i>{{ schedule.time }}</p>
        <span v-if="schedule.is_live == true"> Live</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "NestEventCard",
  components: {},
  props: ["schedule", "type"]
};
</script>

<style lang="scss">
@import "@/style/class-card.scss";
</style>
