<template>
  <div class="class-content">
    <ul class="nav nav-pills mb-3" id="pills-tab" role="tablist">
      <li class="nav-item" role="presentation">
        <button
          class="nav-link active"
          id="pills-scheduled-tab"
          data-bs-toggle="pill"
          data-bs-target="#pills-scheduled"
          type="button"
          role="tab"
          aria-controls="pills-scheduled"
          aria-selected="true"
        >
          Up Coming
        </button>
      </li>
      <li class="nav-item" role="presentation">
        <button
          class="nav-link"
          id="pills-history-tab"
          data-bs-toggle="pill"
          data-bs-target="#pills-history"
          type="button"
          role="tab"
          aria-controls="pills-history"
          aria-selected="false"
        >
          History
        </button>
      </li>
    </ul>
    <div class="tab-content" id="pills-tabContent">
      <div
        class="tab-pane fade show active"
        id="pills-scheduled"
        role="tabpanel"
        aria-labelledby="pills-scheduled-tab"
      >
        <div class="row">
          <webinar-loader v-if="classLoader == true"></webinar-loader>
          <NestEventCard
            v-else
            v-for="(upcomingSchedule,
            upcomingScheduleIndex) in upcomingSchedules"
            :key="upcomingScheduleIndex"
            :schedule="upcomingSchedule"
            :type="'upcoming'"
          />
          <div
            class="empty"
            v-if="upcomingSchedules.length == 0 && classLoader == false"
          >
            {{ errorMessage }}
          </div>
        </div>
      </div>
      <div
        class="tab-pane fade"
        id="pills-history"
        role="tabpanel"
        aria-labelledby="pills-history-tab"
      >
        <div class="row">
          <webinar-loader v-if="classLoader == true"></webinar-loader>
          <NestEventCard
            v-else
            v-for="(completedSchedule,
            completedScheduleIndex) in completedSchedules"
            :key="completedScheduleIndex"
            :schedule="completedSchedule"
            :type="'completed'"
          />
          <div
            class="empty"
            v-if="completedSchedules.length == 0 && classLoader == false"
          >
            {{ errorMessage }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import NestEventCard from "@/components/NestEventCard.vue";
import NestEventService from "@/services/NestEventService.js";
import errorLog from "@/errorLog";
import WebinarLoader from "@/components/loaders/WebinarLoader";

export default {
  name: "NestEventTab",
  components: {
    NestEventCard,
    WebinarLoader
  },
  data() {
    return {
      upcomingSchedules: [],
      classLoader: true,
      completedSchedules: [],
      errorMessage: "No schedule found "
    };
  },
  computed: {
    nestMember() {
      console.log(this.$store.getters["privilege/isNestMember"]);
      return this.$store.getters["privilege/isNestMember"];
    }
  },
  watch: {
    nestMember: function() {
      if (this.$store.getters["privilege/isNestMember"] == false) {
        this.$toast.error("No permission to access this page ");
        this.$router.push({ name: "dashboardTab" });
      }
    }
  },
  created() {
    this.getSchedules();
  },
  methods: {
    async getSchedules() {
      this.classLoader = true;
      await NestEventService.getSchedules()
        .then(result => {
          if (result.data.status == "success") {
            this.classLoader = false;
            this.upcomingSchedules = result.data.upcoming;
            this.completedSchedules = result.data.completed;
          } else {
            this.classLoader = false;
            this.errorMessage = result.data.message;
          }
        })
        .catch(error => {
          this.classLoader = false;
          errorLog.log(error);
        });
    }
  }
};
</script>

<style lang="scss">
@import "@/style/class-tab.scss";
</style>
